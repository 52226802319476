/* eslint-disable no-unused-vars */
import {
  ref, reactive, watch, computed, onMounted, onBeforeUnmount,
} from '@vue/composition-api'
import fbmService from '@/http/requests/fbm/fbmService'
import { useStorage } from '@vueuse/core'
import { useRouter } from '@core/utils/utils'
import useUtilities from '@core/composables/useUtils'
import { v4 as uuidv4 } from 'uuid'
import Pager from '@core/dev-extreme/utils/pager'
import prepMaterialService from '@/http/requests/prep-material/prepMaterialService'

const defaultSelectedOrder = []
const selectedOrders = useStorage('selected-orders-for-bulk-label', defaultSelectedOrder)
const defaultSelectedOrderIds = []
const selectedOrderIds = useStorage('selected-orders-ids', defaultSelectedOrderIds)
const defaultShipFromId = null
const selectedShipFromId = useStorage('selected-ship-from-id', defaultShipFromId)
const defaultFromName = ''
const selectedFromName = useStorage('selected-from-name', defaultFromName)
const defaultSelectedCompanyId = ''
const selectedCompanyId = useStorage('selected-company-id', defaultSelectedCompanyId)
const defaultSelectedOrderSKU = ''
const selectedOrderSKU = useStorage('selected-order-sku', defaultSelectedOrderSKU)
const prepMaterials = ref([])

export default function useBuyBulkLabels() {
  // companyId is required to fetch address data
  function setOrderIds(orderIds, companyId, orderSKU) {
    selectedOrderIds.value = [...orderIds]
    selectedCompanyId.value = companyId
    selectedOrderSKU.value = orderSKU
  }
  async function setOrdersForBuyBulkLabel(shipFromId, fromName, autoReserve = true) {
    const payload = {
      fbmOrderIdList: selectedOrderIds.value,
      orderSKU: selectedOrderSKU.value,
      fromName: fromName,
      shipFromId: shipFromId,
      autoReserve: autoReserve,
    }
    const result = await fbmService.buyBulkShippingLabel(payload)
    selectedOrders.value = []
    const ordersWithId = result.map(order => ({
      ...order,
      uniqueId: uuidv4(),
    }))
    selectedOrders.value = [...ordersWithId]
  }

  function setFromData(shipFromId, fromName) {
    selectedShipFromId.value = shipFromId
    selectedFromName.value = fromName
  }

  const orderIds = computed(() => {
    let ids = []
    if (selectedOrders.value && selectedOrders.value.length > 0) {
      ids = selectedOrders.value.map(el => el.fbmOrderId)
    }
    return ids
  })

  const orderNumbers = computed(() => {
    let ids = []
    if (selectedOrders.value && selectedOrders.value.length > 0) {
      ids = selectedOrders.value.map(el => el.orderNumber)
    }
    return ids
  })

  const storeName = computed(() => {
    let name = ''
    if (selectedOrders.value && selectedOrders.value.length > 0) {
      name = selectedOrders.value[0].storeName
    }
    return name
  })

  const totalOrderCount = computed(() => {
    let count = 0
    if (selectedOrders.value && selectedOrders.value.length > 0) {
      count = selectedOrders.value.length
    }
    return count
  })

  const totalItemCount = computed(() => {
    let count = 0
    if (selectedOrders.value && selectedOrders.value.length > 0) {
      count = selectedOrders.value.length * selectedOrders.value[0].quantity
    }
    return count
  })

  const platform = computed(() => {
    let name = ''
    if (selectedOrders.value && selectedOrders.value.length > 0) {
      name = selectedOrders.value[0].platform
    }
    return name
  })

  const { router } = useRouter()

  function removeOrder(fbmOrderId) {
    selectedOrders.value = selectedOrders.value.filter(order => order.fbmOrderId !== fbmOrderId)
    if (selectedOrders.value.length === 0) {
      router.go(-1)
    }
  }
  function findBestPshOption(options, serviceName = null) {
    if (options.length === 0) return null

    let cheapest = null
    let minAmount = Infinity
    let serviceNameFound = false

    for (let i = 0; i < options.length; i++) {
      const option = options[i]
      const amount = parseFloat(option.amountLocal)

      if (serviceName) {
        if (option.servicelevel.name === serviceName) {
          serviceNameFound = true
          if (amount < minAmount) {
            minAmount = amount
            cheapest = option
          }
        }
      } else if (amount < minAmount) {
        minAmount = amount
        cheapest = option
      }
    }
    if (serviceName && !serviceNameFound) {
      cheapest = options.reduce((min, option) => {
        const amount = parseFloat(option.amountLocal)
        return amount < min.amount ? option : min
      }, { amount: Infinity })
    }

    return cheapest
  }
  function setBestAmazonOption(amazonOptions = null, serviceName = null) {
    if (amazonOptions === null) {
      return null
    }
    let bestAmazonOption = null
    let minAmount = Infinity

    for (let i = 0; i < amazonOptions.length; i++) {
      const option = amazonOptions[i]
      const amount = parseFloat(option.totalCharge.value)

      if (serviceName) {
        if (option.serviceName === serviceName && amount < minAmount) {
          minAmount = amount
          bestAmazonOption = option
        }
      } else if (amount < minAmount) {
        minAmount = amount
        bestAmazonOption = option
      }
    }

    return bestAmazonOption
  }

  function formatDeliveryWindow(promise) {
    if (!promise || !promise.deliveryWindow) return ''

    const { start, end } = promise.deliveryWindow

    // Extracting and formatting start date
    const startMonth = start.month.charAt(0).toUpperCase() + start.month.slice(1).toLowerCase()
    const startDay = start.dayOfMonth
    const startYear = start.year

    // Extracting and formatting end date
    const endMonth = end.month.charAt(0).toUpperCase() + end.month.slice(1).toLowerCase()
    const endDay = end.dayOfMonth
    const endYear = end.year

    // Checking if start and end dates are the same
    if (startDay === endDay && startMonth === endMonth && startYear === endYear) {
      return `Delivery on ${startMonth} ${startDay}, ${startYear}`
    } if (startMonth === endMonth && startYear === endYear) {
      return `Delivery in ${startMonth}, ${startDay} - ${endDay} ${startYear}`
    }
    return `Delivery from ${startMonth} ${startDay}, ${startYear} to ${endMonth} ${endDay}, ${endYear}`
  }

  const { formatCurrency } = useUtilities()
  function getValueAddedServices(data) {
    if (!data || !data.availableValueAddedServiceGroups) return []

    // Extracting and formatting the value-added services
    const services = data.availableValueAddedServiceGroups.flatMap(group => group.valueAddedServices.map(service => ({
      text: `${service.name} (${formatCurrency(service.cost.value, service.cost.unit)})`,
      value: service.id,
      cost: service.cost.value, // Including cost for sorting
    })))

    // Sorting services by cost value
    services.sort((a, b) => a.cost - b.cost)

    return services
  }

  async function getPrepMaterialsForBulk() {
    const pager = new Pager({})
    const pageable = pager.staticPageable
    const filters = {}
    const response = await prepMaterialService.getByQuery(filters, pageable)
    prepMaterials.value = response.data.content.filter(el => el.status === 'active')
    prepMaterials.value = prepMaterials.value.map(el => ({ ...el, disabled: el.quantity === 0 && el.inventoryType === 'INVENTORY' }))
  }

  return {
    setOrderIds,
    setOrdersForBuyBulkLabel,
    setBestAmazonOption,
    selectedOrders,
    removeOrder,
    findBestPshOption,
    formatDeliveryWindow,
    getValueAddedServices,
    setFromData,
    platform,
    storeName,
    totalOrderCount,
    totalItemCount,
    orderIds,
    selectedOrderIds,
    selectedShipFromId,
    selectedFromName,
    selectedCompanyId,
    orderNumbers,
    selectedOrderSKU,
    getPrepMaterialsForBulk,
    prepMaterials,
  }
}
