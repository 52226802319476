<template>
  <dx-util-popup
    ref="shippingRateListPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="true"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="popupShown"
    @hidden="popupHidden"
  >
    <dx-util-scroll-view width="100%" height="100%">
      <div class="row">
        <div :class="rates.hasAmazonRates ? 'col-6' : 'col-12'">
          <div class="bg-light-primary rounded-top p-1">
            <div class="psh-header mb-0 d-flex align-items-center">
              <div class="mr-1 border-0">
                <img height="32" :src="require('@/assets/images/logo/psh-logo.png')" alt="">
              </div>
              <div class="d-flex flex-column">
                <h4 class="card-title mb-25">
                  PSH Rates
                </h4>
              </div>
            </div>
          </div>
          <div class="px-1">
            <table class="w-100">
              <thead>
              <tr class="border-bottom">
                <th></th>
                <th>Carrier</th>
                <th>Shipping Service</th>
                <th>Delivery Charge</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="rate in rates.pshRates" :key="rate.objectId"
                  class="border-bottom" :class="selectedRate.objectId === rate.objectId ? 'bg-info' : ''"
                  @click="handleSubmit(rate)"
              >
                <td class="px-1">
                  <p-icon :name="selectedRate.objectId === rate.objectId ? 'feather-disc' : ''" :color="selectedRate.objectId === rate.objectId ? 'white' : ''" />
                </td>
                <td>
                  {{ rate.provider }}
                </td>
                <td>
                  {{ rate.servicelevel.name }}
                </td>
                <td>
                  ${{ rate.amountLocal }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="rates.hasAmazonRates" class="col-6">
          <div class="bg-light-primary rounded-top p-1">
            <div class="psh-header mb-0 d-flex align-items-center">
              <div class="mr-1 border-0">
                <img height="32" :src="require('@/assets/images/svg/ecommerce/amazonsp.svg')" alt="">
              </div>
              <div class="d-flex flex-column">
                <h4 class="card-title mb-25">
                  Amazon Rates
                </h4>
              </div>
            </div>
          </div>
          <div class="px-1">
            <table class="w-100">
              <thead>
              <tr class="border-bottom">
                <th></th>
                <th>Carrier</th>
                <th>Shipping Service</th>
                <th>Delivery Charge</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="rate in rates.amazonRates" :key="rate.rateId"
                  class="border-bottom" :class="selectedRate.objectId === rate.rateId ? 'bg-info' : ''"
                  @click="handleSubmit(rate)"
                >
                <td class="px-1">
                  <p-icon :name="selectedRate.objectId === rate.rateId ? 'feather-disc' : ''" :color="selectedRate.objectId === rate.rateId ? 'white' : ''" />
                </td>
                <td>
                  {{ rate.carrierName }}
                </td>
                <td>
                  {{ rate.serviceName }}
                </td>
                <td>
                  ${{ rate.totalCharge.value }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </dx-util-scroll-view>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'

export default {
  components: {},
  props: {
    componentId: {
      type: String,
      default: '',
    },
    rates: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      popupTitle: '',
      selectedRate: '',
    }
  },
  computed: {
    shippingRateListPopup() {
      return this.$refs.shippingRateListPopupRef.instance
    },
    amazonRates() {
      return ''
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.shippingRateListPopup.show()
      },
    },
  },
  methods: {
    popupShown() {
      this.popupTitle = 'Select a shipping rate option'
    },
    popupHidden() {
      // Clear form when popup is closed
    },
    closePopup() {
      this.shippingRateListPopup.hide()
    },
    handleSubmit(rate) {
      this.selectedRate = rate
      this.$emit('emit-rate-selected', this.selectedRate)
      setTimeout(() => {
        this.closePopup()
      }, 1000)
    },
    updateSelectedService(e) {
      //
    },
  },
}
</script>

<style lang="scss" scoped>
th, td {
  height: 42px;
  padding: 4px;
}
tr:hover {
  background-color: rgba(30, 177, 245, 0.21);
  cursor: pointer;
  transition: background-color 0.5s ease, color 0.5s ease;
}
</style>
