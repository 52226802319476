<template>
  <div class="row">
    <div class="col-12 my-0">
      <div class="card mb-1 mt-0 border">
        <div class="border rounded-bottom">
          <table class="w-100">
            <tr class="bg-light-primary">
              <th>
                Charge Name
              </th>
              <th>
                Rate
              </th>
              <th>Count</th>
              <th>Total</th>
              <th class="text-right">
                <dx-util-button :icon="!showAddRate ? 'add' : 'remove'" :type="!showAddRate ? 'default' : 'danger'" @click="showAddRate = !showAddRate" />
              </th>
            </tr>
            <tr>
              <td colspan="5">
                <div v-if="showAddRate" class="card-title  pt-half">
                  <dx-util-validation-group ref="chargeValidationRef">
                    <div class="px-half d-flex" style="gap: 4px">
                      <div style="padding-top: 8px">
                        <dx-util-switch v-model="selectedChargeType" style="width: 80px;" switched-on-text="Custom" switched-off-text="Manual" @value-changed="changeChargeType" />
                      </div>
                      <div v-if="newCharge.chargeType === chargeTypeEnum.PREP_MATERIAL.value" class="d-flex align-items-center flex-grow-1" style="gap: 4px">
                        <dx-util-select-box
                          v-model="newCharge.costId"
                          :data-source="prepMaterials"
                          display-expr="title" value-expr="id"
                          :show-clear-button="false"
                          :search-enabled="true" search-mode="contains"
                          :drop-down-options="{ height: 300, minWidth: 500 }" item-template="item"
                          label="Prep Item" placeholder="Select an item"
                          class="flex flex-grow-1"
                          @selection-changed="onSelectionChanged"
                        >
                          <template #item="{data}">
                            <div class="d-flex text-left">
                              <div class="">
                                {{ data.title }}
                              </div>
                              <div class="flex-grow-1" />
                              <div class="">
                                {{ formatCurrency(data.salePrice) }}
                              </div>
                              <div class="text-right d-inline" style="width: 90px;">
                                <div :class="resolveQuantity(data.quantity)">
                                  {{ formatNumber(data.quantity) }}
                                </div>
                              </div>
                            </div>
                          </template>
                          <dx-util-validator>
                            <dx-util-required-rule message="Prep Item selection is required" />
                          </dx-util-validator>
                        </dx-util-select-box>
                        <dx-util-number-box v-model="newCharge.count" :min="1" label="Count" placeholder="Count" width="70">
                          <dx-util-validator>
                            <dx-util-required-rule message="Count is required" />
                            <dx-util-range-rule :min="1" message="Count is required field" />
                          </dx-util-validator>
                        </dx-util-number-box>
                      </div>
                      <div v-else class="d-flex align-items-center flex-grow-1" style="gap: 4px">
                        <dx-util-text-box
                          v-if="newCharge.chargeType === chargeTypeEnum.OTHER.value"
                          v-model="newCharge.title" label="Title"
                          class="flex-grow-1"
                          placeholder="Enter title"
                        >
                          <dx-util-validator>
                            <dx-util-required-rule message="Title is required" />
                          </dx-util-validator>
                        </dx-util-text-box>
                        <dx-util-number-box
                          v-if="newCharge.chargeType === chargeTypeEnum.OTHER.value"
                          v-model="newCharge.rate" label="Rate"
                          placeholder="Enter rate/sale price"
                          style="width: 90px"
                        >
                          <dx-util-validator>
                            <dx-util-required-rule message="Rate is required" />
                            <dx-util-range-rule :min="0.1" message="Cost should be greater than 0" />
                          </dx-util-validator>
                        </dx-util-number-box>
                      </div>
                      <dx-util-drop-down-button
                        id="chargeAddActions"
                        :split-button="false"
                        :use-select-mode="true"
                        :drop-down-options="{ width: 200 }"
                        :show-arrow-icon="false"
                        icon="save"
                        display-expr="save"
                        type="success"
                        drop-down-content-template="dropDownTemplate"
                        class="px-0 text-center"
                        style="height: 44px; width: 36px; padding-top: 10px;"
                      >
                        <template #dropDownTemplate>
                          <div class="d-flex flex-column">
                            <dx-util-button text="Add Charge" class="mx-0 w-100 text-left" @click="saveCharge" />
                            <dx-util-button text="Add Charge To All Orders" class="mx-0 w-100 text-left" @click="saveCharges" />
                          </div>
                        </template>
                      </dx-util-drop-down-button>
                    </div>
                  </dx-util-validation-group>
                </div>
              </td>
            </tr>
            <tr v-for="charge in charges" :key="charge.id">
              <td class="text-left pl-half">
                {{ charge.title }}
              </td>
              <td>
                {{ formatCurrency(charge.rate) }}
              </td>
              <td>
                {{ charge.count }}
              </td>
              <td>
                {{ formatCurrency(charge.count * charge.rate) }}
              </td>
              <td class="text-right">
                <span class="delete-btn" role="button" @click="deleteCharge(charge.id)">Delete</span>
                <span class="delete-btn" role="button" @click="deleteAllCharges(charge.costId)">Delete All </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { currencyFormatter } from '@core/utils/filter'
// import { confirm } from 'devextreme/ui/dialog'
import chargeTypeEnum from '@/components/charge/charge-type.enum.js'
import fbmService from '@/http/requests/fbm/fbmService'
import useBuyBulkLabels from '@/views/apps/outbound/fbm-shipping/components/useBuyBulkLabels'

export default {
  setup() {
    const { selectedOrders, orderIds, prepMaterials } = useBuyBulkLabels()
    return {
      prepMaterials,
      selectedOrders,
      orderIds,
    }
  },
  props: {
    defaultCharges: {
      type: Array,
      default: () => [],
    },
    orderId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showAddRate: false,
      selectedChargeType: false,
      chargeValidationRef: 'targetGroup1',
      charges: [],
      showAddChargeItem: false,
      newCharges: [],
      newCharge: {
        chargeType: chargeTypeEnum.PREP_MATERIAL.value,
        itemId: this.orderId,
        costId: null,
        title: '',
        rate: 0,
        count: 1,
        notes: '',
      },
      selectedToBeChargedId: null,
      chargeTypeEnum,
    }
  },
  computed: {
    chargeValidationGroup() {
      return this.$refs.chargeValidationRef.instance
    },
  },
  watch: {
    defaultCharges: {
      immediate: true,
      deep: true,
      handler(newCharges) {
        this.charges = newCharges
      },
    },
  },
  methods: {
    async saveCharge(e) {
      const validationResult = this.chargeValidationGroup.validate()
      if (validationResult.isValid) {
        await fbmService.createCharges([this.newCharge])
        this.$emit('emit-charge-saved')
        this.chargeValidationGroup.resetValues()
      }
    },
    async saveCharges(e) {
      const validationResult = this.chargeValidationGroup.validate()
      const chargesForAll = []
      this.selectedOrders.forEach(order => {
        const chargeSingle = { ...this.newCharge, itemId: order.fbmOrderId }
        chargesForAll.push(chargeSingle)
      })
      if (validationResult.isValid) {
        await fbmService.createCharges(chargesForAll)
        this.$emit('emit-charge-saved')
        this.chargeValidationGroup.resetValues()
      }
    },
    async deleteCharge(chargeId) {
      this.$swal({
        title: 'Are you sure you want to delete the charge?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete charge',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await fbmService.deleteCharge(chargeId)
          this.$emit('emit-charge-saved')
        }
      })
    },
    deleteAllCharges(costId) {
      this.$swal({
        title: 'Are you sure you want to delete this charge from all orders?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete charge',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const payload = {
            fbmOrderIdList: this.orderIds,
            costId: costId,
          }
          await fbmService.deleteChargesInBulk(payload)
          this.$emit('emit-charge-saved')
        }
      })
    },
    resolveQuantity(quantity) {
      if (quantity === 0) return 'badge badge-light-danger'
      if (quantity < 50) return 'badge badge-light-warning'
      return 'badge badge-light-success'
    },
    formatNumber(number) {
      if (typeof number !== 'number' || Number.isNaN(number)) {
        return 'N/A'
      }
      return number.toLocaleString('en-US', { maximumFractionDigits: 0 })
    },
    formatCurrency(v) {
      return currencyFormatter(v)
    },
    onSelectionChanged(e) {
      const selectedCharge = e.selectedItem
      this.newCharge.costId = selectedCharge.id
      this.newCharge.title = selectedCharge.title
      this.newCharge.rate = selectedCharge.salePrice
    },
    changeChargeType() {
      this.newCharge.chargeType = this.newCharge.chargeType === chargeTypeEnum.PREP_MATERIAL.value ? chargeTypeEnum.OTHER.value : chargeTypeEnum.PREP_MATERIAL.value
      this.selectedChargeType = this.newCharge.chargeType === chargeTypeEnum.OTHER.value
      if (this.newCharge.chargeType === chargeTypeEnum.OTHER.value) {
        this.newCharge.title = ''
        this.newCharge.rate = 0
        this.newCharge.count = 1
        this.newCharge.costId = null
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.text-button {
  margin-left: 16px;
  transition: color 0.3s ease;
}

.text-button:hover {
  color: #66bcf6;
}

.delete-btn {
  margin-left: 16px;
  color: #e4388b;
  transition: color 0.3s ease;
}

.delete-btn:hover {
  color: #c56593;
}

tr {
  font-size: 13px;
}

tr, td {
  padding: 8px;
  padding-bottom: 8px;
  text-align: left;
}

tr:first-child th:first-child {
  text-align: left;
  padding-left: 6px;
}
</style>

<style lang="scss">
#chargeAddActions  {
  .dx-icon {
    font-size: 1.3rem;
    color: #FFFFFF;
    margin-left: 4px;
  }
  .dx-button-content {
    text-align: left;
    background-color: #28AE60;
    border-radius: 3px;

  }
  .dx-buttongroup-item {
    padding-left: 0;
    padding-right: 0;
  }
  .dx-dropdownbutton-action:not(.dx-buttongroup-last-item).dx-button {
    border-right-width: 14px;
  }
}
</style>
