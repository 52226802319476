export default function useUtilities() {
  function formatCurrency(value, currency = 'USD') {
    // Ensure value is a number
    const numericValue = parseFloat(value)
    if (Number.isNaN(numericValue)) {
      return null // Return null or handle invalid number appropriately
    }

    // Create an Intl.NumberFormat object for the specified currency
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2, // Always show 2 decimal places
      maximumFractionDigits: 2,
    })

    // Format the value and return
    return formatter.format(numericValue)
  }
  return {
    formatCurrency,
  }
}
