<template>
  <div class="container-fluid card">
    <div class="row p-half align-items-center" style="gap: 10px">
      <dx-util-button icon="back" type="default" @click="$router.go(-1)" />
      <dx-util-select-box
        v-model="shipFromId"
        :data-source="fromAddresses"
        display-expr="text"
        value-expr="id"
        item-template="item"
        label-mode="floating"
        label="Ship From"
        style="max-width: 400px"
        class="mb-half flex-grow-1"
        :show-clear-button="false"
        @value-changed="updateRatesByShipFromId"
      >
        <template #item="{ data }">
          <div v-html="data.textHtml" />
        </template>
      </dx-util-select-box>
      <div :key="refreshPageKey" class="d-flex align-items-center" style="gap: 4px">
        <platform-logo :platform="platform" />
        <span class="text-info">{{ storeName }}</span>
        <div class="flex-grow-1"></div>
        <div class="text-center badge badge-light-info px-1" style="padding: 1px">
          <small class="d-block">Order Count</small>
          <h3>
            {{ totalOrderCount }}
          </h3>
        </div>
        <div class="text-center ml-2 badge badge-light-success px-1" style="padding: 1px">
          <small class="d-block">Item Count</small>
          <h3>
            {{ totalItemCount }}
          </h3>
        </div>
      </div>
      <div class="flex-grow-1"></div>
      <dx-util-button text="Buy All Labels" type="success" @click="startTriggerBuy" />
      <dx-util-button text="Print All Labels" type="default" @click="downloadLabels" />
      <dx-util-button text="Print Pack Slips" type="default" @click="printPackingSlips" />
      <dx-util-button text="Cancel Reservations" type="danger" @click="cancelReservations" />
    </div>
    <div class="row py-half text-warning font-weight-bold text-center border-bottom" style="font-size: 14px">
      <div class="col-12 col-sm-4 col-md-3 text-left">
        Order Info
      </div>
      <div class="col-12 col-sm-4 col-md-2">
        Dimensions & Weight & Charges
      </div>
      <div class="col-12 col-sm-4 col-md-3">
        Ship Method
      </div>
      <div class="col-12 col-sm-4 col-md-2">
        Declared Value / Confirmation
      </div>
      <div class="col-12 col-sm-4 col-md-2">
        <div class="row">
          <div class="col-6">
            Total
          </div>
          <div class="col-6">
            Actions
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 h-100">
        <div>
          <div v-for="(order, i) in selectedOrders" :key="order.uniqueId">
            <bulk-label-item :order="order" :order-index="i" :buy-label="triggerBuy" @emit-update-dimension="updateRates" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <bulk-reservation :component-id="bulkReservationCompId" :items="selectedOrders" />
    </div>
  </div>
</template>

<script>
import useBuyBulkLabels from '@/views/apps/outbound/fbm-shipping/components/useBuyBulkLabels'
import BulkLabelItem from '@/views/apps/outbound/fbm-shipping/components/BulkLabelItem.vue'
import commonService from '@/http/requests/common/commonEntityService'
import fbmService from '@/http/requests/fbm/fbmService'
import usePackingSlipDownload from '@/views/apps/outbound/fbm-shipping/components/packing-slip-pdf'
import { v4 as uuidv4 } from 'uuid'
import PlatformLogo from '@/views/apps/outbound/fbm-shipping/components/PlatformLogo.vue'
import { Notify } from '@/@robustshell/utils'
import useConfirmation from '@/libs/app/confirmation'

export default {
  components: {
    PlatformLogo,
    'bulk-label-item': BulkLabelItem,
    'bulk-reservation': () => import('@/views/apps/outbound/fbm-shipping/components/BulkReservation.vue'),
  },
  data() {
    return {
      shipFromId: '',
      fromAddresses: [],
      triggerBuy: '',
      bulkReservationCompId: '',
      ordersWithMissingReservation: [],
      refreshPageKey: '',
    }
  },
  setup() {
    const {
      platform,
      storeName,
      totalOrderCount,
      totalItemCount,
      selectedOrders,
      setOrdersForBuyBulkLabel,
      orderIds,
      selectedOrderIds,
      selectedCompanyId,
      selectedShipFromId,
      selectedFromName,
      setFromData,
      selectedOrderSKU,
      getPrepMaterialsForBulk,
    } = useBuyBulkLabels()

    const { downloadPackingSlips } = usePackingSlipDownload()
    const { pshConfirm } = useConfirmation()
    return {
      selectedOrders,
      platform,
      storeName,
      totalOrderCount,
      totalItemCount,
      setOrdersForBuyBulkLabel,
      downloadPackingSlips,
      orderIds,
      selectedOrderIds,
      selectedCompanyId,
      selectedShipFromId,
      selectedFromName,
      setFromData,
      pshConfirm,
      selectedOrderSKU,
      getPrepMaterialsForBulk,
    }
  },
  created() {
    // this.checkReservation()
    // this.bulkReservationCompId = this.$uid()
    this.getCompanyAddresses()
  },
  mounted() {
    this.getPrepMaterialsForBulk()
  },
  methods: {
    // checkReservation() {
    //   this.ordersWithMissingReservation = []
    //   this.selectedOrders.forEach(el => {
    //     if (el.requiredReserveCount > 0) {
    //       this.ordersWithMissingReservation.push(el)
    //     }
    //   })
    //   if (this.ordersWithMissingReservation > 0) {
    //     this.bulkReservationCompId = this.$uid()
    //   }
    // },
    async startTriggerBuy() {
      const res = await fbmService.checkItemsReservations(this.orderIds).catch(() => {
        Notify.error('Failed to check item reservations')
      })
      const missingReservations = res.some(item => item.requiredReserveCount > 0)
      if (!missingReservations) {
        this.triggerBuy = this.$uid()
      } else {
        const confirm = await this.pshConfirm(
          'Missing Inventories',
          'Are you sure you want to continue with missing inventories? <br/> You need to manually correct your inventories later.',
          'exclamation-circle-fill',
          'Yes, do it.',
          'Cancel',
          'default',
        )
        if (confirm) {
          this.triggerBuy = this.$uid()
        }
      }
    },
    async updateRates(dimensions) {
      const orderIds = this.selectedOrders.map(el => el.fbmOrderId)
      const payload = {
        labelDimension: dimensions,
        fbmOrderIds: orderIds,
        shipFromId: this.selectedShipFromId,
        fromName: this.selectedFromName,
      }
      const result = await fbmService.updateBulkShippingLabel(payload)
      this.refreshPageKey = uuidv4()
      if (result) {
        this.refreshPageKey = uuidv4()
        this.selectedOrders = []
        const ordersWithId = result.map(order => ({
          ...order,
          uniqueId: uuidv4(),
        }))
        this.selectedOrders = [...ordersWithId]
      }
    },
    getCompanyAddresses() {
      if (this.selectedCompanyId === 0) {
        return
      }
      this.fromAddresses = []
      commonService
        .fetchAddressesByTenantAndCompany(this.selectedCompanyId)
        .then(result => {
          const { data } = result
          data.forEach(item => {
            let line = item.line1
            line += item.line2 ? ` ${item.line2}` : ''
            line += item.line3 ? ` ${item.line3}` : ''
            const addressStringHtml = `${item.name}<br />${line}<br />${item.city}, ${item.state} ${item.zipcode} ${item.country}`
            const addressString = `${item.name} ${line}  ${item.city}, ${item.state} ${item.zipcode} ${item.country}`
            this.fromAddresses.push({
              id: `${item.id}~${item.name}`,
              textHtml: addressStringHtml,
              text: addressString,
              country: item.country,
            })
          })
        }).then(() => {
          this.shipFromId = this.fromAddresses[0]?.id
        })
    },
    async updateRatesByShipFromId(e) {
      const idAndFromName = e.value
      const strArray = idAndFromName.split('~')
      this.setFromData(strArray[0], strArray[1])
      await this.setOrdersForBuyBulkLabel(strArray[0], strArray[1])
    },
    async printPackingSlips() {
      const orderIds = this.selectedOrders.map(el => el.fbmOrderId)
      const result = await fbmService.getOrderAllInfo(orderIds)
      await this.downloadPackingSlips(result)
    },
    async cancelReservations() {
      const reservations = []
      this.selectedOrders.forEach(order => {
        reservations.push(order.fbmOrderItemId)
      })
      const confirm = await this.pshConfirm(
        'Cancel Reservation Confirmation',
        `Are you sure you want to cancel the reservations? <br/> Number of Cancelled Reservation Orders:  <span style="color: orange;">${this.selectedOrders.length}</span>`,
        'exclamation-circle-fill',
        'Delete',
        'Cancel',
        'default',
      )
      if (confirm) {
        await fbmService.cancelBulkReservation(reservations)
        await this.setOrdersForBuyBulkLabel(this.selectedShipFromId, this.selectedFromName, false)
      }
    },
    async downloadLabels() {
      const orderIds = this.selectedOrders.map(el => el.fbmOrderId)
      const response = await fbmService.downloadLabelsInBulk(orderIds)
      const contentType = response.headers['content-type']
      const contentDisposition = response.headers['content-disposition']
      const fileParts = contentDisposition.split('filename=')[1].replaceAll('"', '').split('.')
      const fileExtension = fileParts[1]
      const fileBlob = new Blob([response.data], { type: contentType })
      const anchor = document.createElement('a')
      anchor.href = window.URL.createObjectURL(fileBlob)
      anchor.download = `Merged_Labels.${fileExtension}`
      anchor.click()
    },
  },
  beforeRouteLeave(to, from, next) {
    this.selectedOrders = []
    this.selectedShipFromId = null
    this.selectedFromName = ''
    this.selectedOrderSKU = ''
    next()
  },
}
</script>

<style lang="scss" scoped>

</style>
