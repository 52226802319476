<template>
  <div class="row text-center py-half border-bottom my-1">
    <div class="col-12 text-left col-sm-4 col-md-3">
      <div class="d-flex align-items-start">
        <div role="button" @click="enlargeImage(order.largeImage)">
          <img :src="order.smallImage || require('@/assets/images/undraw/no_images.svg')" style="height: 80px !important; width: 80px !important; margin-right: 0.625rem; margin-top: 0.425rem;" class="rounded text-white">
        </div>
        <div class="w-100">
          <div class="text-warning font-weight-bold d-flex justify-content-between align-items-center w-100">
            <div>
              <span class="text-muted">Order #</span>
              <span>
                {{ order.orderNumber }}
              </span>
            </div>
            <div class="">
              <dx-util-button :text="order.requiredReserveCount > 0 ? 'Reserve': `Reserved: ${order.reserveCount}`" :type="resolveReservation(order)" @click="openFbmInventory(order)" />
              <dx-util-button icon="remove" hint="Cancel Reservation" type="danger" class="ml-half" @click="cancelReservation(order)" />
            </div>
          </div>
          <div>
            {{ order.productTitle }}
          </div>
          <small class="d-block">
            SKU: {{ order.sku }}
          </small>
          <small v-if="order.asin">
            ASIN: {{ order.asin }}
          </small>
          <small v-if="order.upc">
            UPC: {{ order.upc }}
          </small>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-4 col-md-3">
      <div v-if="orderIndex === 0" class="d-flex flex-wrap mb-1">
        <dx-util-number-box v-model="order.labelDimension.length" label="Length" :format="inchOrCm" class="mr-half" style="max-width: 70px" :min="1">
          <dx-util-validator>
            <dx-util-range-rule :min="0.1" message="Length is required field" />
          </dx-util-validator>
        </dx-util-number-box>
        <dx-util-number-box v-model="order.labelDimension.width" label="Width" :format="inchOrCm" class="mr-half" style="max-width: 70px" :min="1">
          <dx-util-validator>
            <dx-util-range-rule :min="0.1" message="Width is required field" />
          </dx-util-validator>
        </dx-util-number-box>
        <dx-util-number-box v-model="order.labelDimension.height" label="Height" :format="inchOrCm" class="mr-half" :min="1" style="max-width: 70px">
          <dx-util-validator>
            <dx-util-range-rule :min="0.1" message="Height is required field" />
          </dx-util-validator>
        </dx-util-number-box>
        <dx-util-number-box v-model="order.labelDimension.weightLb" label="Weight" :format="lbOrKg" :min="0" class="mr-half" style="max-width: 70px" />
        <dx-util-number-box v-model="order.labelDimension.weightOz" label="Weight" :format="ozOrGr" class="mr-half" :min="0" style="max-width: 70px" />
        <div class="pt-half">
          <dx-util-button id="btn" icon="save" type="success" hint="Update" @click="updateBoxWeightAndDimensions" />
        </div>
      </div>
      <div class="">
        <fbm-charges-for-bulk :default-charges="order.chargeItems" :order-id="order.fbmOrderId" @emit-charge-saved="updateItemSelection" />
      </div>
    </div>
    <div class="col-12 col-sm-4 col-md-2 text-left">
      <div v-if="order.shipOption">
        <small>Requested Method:</small>
        <span class="badge" :class="`badge-light-${getShippingMethodFormat(order.shipOption)}`">
          {{ order.shipOption }}
        </span>
      </div>
      <div class="py-half">
        <div class="bg-info text-white p-1 rounded">
          <div class="d-flex justify-content-between">
            <strong>
              {{ selectedService }}
            </strong>
          </div>
          <div>
            <small>
              {{ selectedServiceDetails }}
            </small>
          </div>
          <div class="text-right txt-btn">
            <dx-util-button text="Change" type="warning" style="background-color: white !important; color: #0c0f15; border: none" @click="openShippingRateList" />
          </div>
        </div>
        <div v-if="showPshOption" class="alert alert-warning my-half">
          <div>
            Better option available in PSH rates
            <i class="bi bi-exclamation-circle-fill ml-1" style="font-size: 16px"></i>
          </div>
          <div class="text-white">
            {{ bestPshOption.servicelevel.name }}
            <span>
              {{ formatCurrency(bestPshOption.amount, bestPshOption.currency) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showAmzExtraOptions" class="col-12 col-sm-4 col-md-2 ">
      <div class="d-flex align-items-end justify-content-center">
        <dx-util-number-box v-model="box.insuredValue" class="mr-half w-50" label="Insurance" format="$#0.00" :min="0" :step="0.1" />
        <dx-util-button text="Update" type="default" class="w-50" />
      </div>
      <div>
        <dx-util-select-box
          v-model="selectedValAddServiceGroup"
          label="Requested Confirmation"
          class="mt-2"
          :data-source="valueAddedServiceOptions"
          display-expr="text"
          value-expr="value"
          @value-changed="updateConfirmationMethod"
        />
      </div>
    </div>
    <div v-else class="col-12 col-sm-4 col-md-2 ">
      <div class="alert alert-info">
        This options are available in Amazon Rates
      </div>
    </div>
    <div class="col-12 col-sm-4 col-md-2">
      <div class="row">
        <div class="col-6 font-weight-bold text-danger">
          <strong>
            {{ formatCurrency(selectedServicePrice) }}
          </strong>
        </div>
        <div v-show="!ratePurchased" class="col-6">
          <div class="col">
            <dx-util-button style="margin-bottom: 3px; min-width: 100px" text="Remove" type="danger" @click="removeOrder(order.fbmOrderId)" />
          </div>
        </div>
        <div v-show="ratePurchased" class="col-6" style="height: 150px">
          <div class="col">
            <dx-util-button text="Print Label" type="default" style="margin-bottom: 3px;min-width: 100px" @click="handleLabelDownload" />
            <dx-util-button text="Print Slip" type="default" style="margin-bottom: 3px;min-width: 100px" @click="printPackingSlips(order.fbmOrderId)" />
            <dx-util-button text="Void Label" type="danger" style="color: orangered;min-width: 100px" @click="handleDeleteLabel(order.fbmOrderId)" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <light-box
        :items="images"
        :index="index"
        :effect="'fade'"
        @close="index = null"
      />
    </div>
    <div class="col-12">
      <shipping-rate-list :component-id="shippingRatesCompId" :rates="order.rates" @emit-rate-selected="changeLabelSelection" />
    </div>
    <div class="col-12">
      <fbm-inventory-search :component-id="fbmInventorySearchCompId" :search-data="searchData" :is-bulk="true" @emit-item-selected="updateItemSelection" />
    </div>
  </div>
</template>

<script>
import fbmService from '@/http/requests/fbm/fbmService'
import CoolLightBox from 'vue-cool-lightbox'
import ShipppingRateList from '@/views/apps/outbound/fbm-shipping/components/ShipppingRateList.vue'
import useBuyBulkLabels from '@/views/apps/outbound/fbm-shipping/components/useBuyBulkLabels'
import useUtilities from '@core/composables/useUtils'
import rateTypesEnum from '@/enums/rateType.enum'
import { Notify } from '@/@robustshell/utils'
import usePackingSlipDownload from '@/views/apps/outbound/fbm-shipping/components/packing-slip-pdf'
import FbmChargesForBulk from '@/views/apps/outbound/fbm-shipping/components/FbmChargesForBulk.vue'
import useConfirmation from '@/libs/app/confirmation'

export default {
  components: {
    'light-box': CoolLightBox,
    'shipping-rate-list': ShipppingRateList,
    'fbm-charges-for-bulk': FbmChargesForBulk,
    'fbm-inventory-search': () => import('./FbmInventorySearch.vue'),
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    boxDimensions: {
      type: Object,
      default: () => {},
    },
    buyLabel: {
      type: String,
      default: '',
    },
    orderIndex: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const {
      selectedOrders,
      orderIds,
      removeOrder,
      findBestPshOption,
      setBestAmazonOption,
      formatDeliveryWindow,
      getValueAddedServices,
      setOrdersForBuyBulkLabel,
      selectedShipFromId,
      selectedFromName,
    } = useBuyBulkLabels()

    const { formatCurrency } = useUtilities()
    const { pshConfirm } = useConfirmation()
    const { downloadPackingSlips } = usePackingSlipDownload()
    return {
      selectedOrders,
      removeOrder,
      findBestPshOption,
      formatCurrency,
      setBestAmazonOption,
      formatDeliveryWindow,
      getValueAddedServices,
      downloadPackingSlips,
      orderIds,
      setOrdersForBuyBulkLabel,
      selectedShipFromId,
      selectedFromName,
      pshConfirm,
    }
  },
  data() {
    return {
      searchData: {},
      fbmInventorySearchCompId: '',
      selected: {
        isAmazonLabel: false,
      },
      isPshRateSelected: false,
      showAmzExtraOptions: true,
      labelIds: [],
      labelUrls: [],
      ratePurchased: false,
      bestPshOption: {},
      bestAmazonOption: {},
      selectedService: '',
      selectedServicePrice: 0,
      selectedServiceDetails: '',
      shippingRatesCompId: '',
      selectedServiceID: '',
      valueAddedServiceOptions: [],
      selectedValAddServiceGroup: null,
      images: [],
      index: null,
      box: {
        id: '',
        count: 1,
        length: 0,
        width: 0,
        height: 0,
        weightLb: 0,
        weightOz: 0,
        description: '',
        insuredValue: 0,
        isHazmat: false,
        singleItemUpdateQty: 0,
        shipBox: [],
      },
    }
  },
  computed: {
    inchOrCm() {
      return this.order.labelDimension.distanceUnit === 'in' ? '#0.0 in' : '#0.0 cm'
    },
    lbOrKg() {
      return this.order.labelDimension.massUnit === 'oz' ? '#0 lb' : '#0 kg'
    },
    ozOrGr() {
      return this.order.labelDimension.massUnit === 'oz' ? '#0 oz' : '#0 gr'
    },
    showPshOption() {
      if (this.isPshRateSelected) {
        return false
      }
      const bestPshOptionAmount = this.bestPshOption?.amount
      const bestAmazonOptionCharge = this.bestAmazonOption?.totalCharge?.value

      const pshAmount = parseFloat(bestPshOptionAmount)
      const amazonAmount = parseFloat(bestAmazonOptionCharge)

      if (Number.isNaN(pshAmount) || Number.isNaN(amazonAmount)) {
        return false
      }
      return pshAmount < amazonAmount
    },
    rateInformation() {
      return {
        companyId: this.order.companyId,
        shipFromId: this.order.shipFromId,
        toAddress: this.order.toAddress,
        shipmentParcels: [this.order.labelDimension],
        notes: '',
        fromName: this.order.companyName,
        fromAddress: this.order.fromAddress,
        referenceKey: 'FBM',
        referenceId: this.order.fbmOrderId,
      }
    },
  },
  watch: {
    orderId: {
      immediate: true,
      deep: true,
      handler() {
      },
    },
    buyLabel: {
      immediate: false,
      deep: false,
      handler() {
        this.buyShippingLabel()
      },
    },
  },
  mounted() {
    this.bestPshOption = { ...this.findBestPshOption(this.order.rates.pshRates, this.order.selectedService) }
    this.bestAmazonOption = { ...this.setBestAmazonOption(this.order.rates.amazonRates, this.order.selectedService) }
    if (!this.bestAmazonOption.rateId) {
      this.updateLabelSelection(this.bestPshOption)
      this.selectRate(this.bestPshOption)
    } else {
      const payload = this.adjustAmazonRateForUIFields(this.bestAmazonOption)
      this.valueAddedServiceOptions = this.getValueAddedServices(this.bestAmazonOption)
      this.selectedValAddServiceGroup = this.valueAddedServiceOptions[0].value

      this.updateLabelSelection(payload)
      this.selectAmazonRate(this.bestAmazonOption)
    }
  },
  methods: {
    getShippingMethodFormat(method) {
      if (method === 'Standard') return 'info'
      return 'danger'
    },
    resolveReservation(order) {
      if (order.reserveCount === 0) {
        return 'danger'
      }
      if (order.reserveCount > 0 && order.requiredReserveCount === 0) {
        return 'success'
      }
      return 'warning'
    },
    async cancelReservation(order) {
      const confirm = await this.pshConfirm(
        'Cancel Reservation Confirmation',
        '<div style="padding-top: 10px;">Are you sure you want to cancel the reservation?</div>',
        'exclamation-circle-fill',
        'Delete reservation',
        'Cancel',
        'default',
      )
      if (confirm) {
        await fbmService.cancelBulkReservation([order.fbmOrderItemId])
        await this.setOrdersForBuyBulkLabel(this.selectedShipFromId, this.selectedFromName, false)
      }
    },
    adjustAmazonRateForUIFields(option) {
      return {
        servicelevel: {
          name: option.serviceName,
        },
        provider: '',
        amount: option.totalCharge.value,
        durationTerms: this.formatDeliveryWindow(option.promise),
        objectId: option.rateId,
      }
    },
    changeLabelSelection(e) {
      // PSH rates has objectOwner property
      if (e.objectOwner) {
        this.isPshRateSelected = true
        this.updateLabelSelection(e)
        this.selectRate(e)
      } else {
        this.isPshRateSelected = false
        const payload = this.adjustAmazonRateForUIFields(e)
        this.valueAddedServiceOptions = this.getValueAddedServices(e)
        this.selectedValAddServiceGroup = this.valueAddedServiceOptions[0].value
        this.updateLabelSelection(payload)
        this.selectAmazonRate(e)
      }
    },
    updateLabelSelection(e) {
      this.selectedService = `${e.provider} ${e.servicelevel.name}`
      this.selectedServicePrice = e.amount
      this.selectedServiceDetails = e.durationTerms
      this.selectedServiceID = e.objectId
    },
    enlargeImage(imageUrl) {
      if (imageUrl === '' || imageUrl === null || imageUrl === undefined) return
      this.images.length = 0
      this.index = 0
      this.images.push(imageUrl)
    },
    openShippingRateList() {
      this.shippingRatesCompId = this.$uid()
    },
    async printPackingSlips(fbmOrderId) {
      const result = await fbmService.getOrderAllInfo([fbmOrderId])
      await this.downloadPackingSlips(result)
    },
    updateConfirmationMethod() {
      this.selected.requestedConfirmation = this.selectedValAddServiceGroup
    },
    selectRate(item) {
      this.selected = { ...this.rateInformation, rateId: item.objectId, rateType: rateTypesEnum.PSH_GO_SHIPPO.value }
      this.showAmzExtraOptions = false
    },
    selectAmazonRate(item) {
      const selectedDocumentOptions = this.selectPrintOption(item.supportedDocumentSpecifications)

      this.selected = {
        ...this.rateInformation,
        rateId: item.rateId,
        requestToken: this.order.rates.requestToken,
        rateType: rateTypesEnum.AMAZON_FBM.value,
        carrier: item.carrierId,
        serviceName: item.serviceName,
        requestedConfirmation: this.selectedValAddServiceGroup,
        requestedDocumentSpecification: {
          format: selectedDocumentOptions?.format,
          size: {
            width: selectedDocumentOptions?.size?.width,
            length: selectedDocumentOptions?.size?.length,
            unit: selectedDocumentOptions?.size?.unit,
          },
          pageLayout: selectedDocumentOptions?.printOptions[0]?.supportedPageLayouts[0],
          needFileJoining: selectedDocumentOptions?.printOptions[0]?.supportedFileJoiningOptions[0],
          requestedDocumentTypes: selectedDocumentOptions?.printOptions[0]?.supportedDocumentDetails.map(el => el.name),
        },
      }
    },
    openFbmInventory(order) {
      this.searchData.orderSku = order.sku
      this.searchData.fbmOrderItemId = order.fbmOrderItemId
      this.searchData.productName = order.productTitle
      this.searchData.storeId = order.storeId
      this.searchData.asin = order.asin
      this.searchData.upc = order.upc
      this.searchData.smallImage = order.smallImage
      this.searchData.largeImage = order.largeImage
      this.fbmInventorySearchCompId = this.$uid()
    },
    updateItemSelection(e) {
      this.setOrdersForBuyBulkLabel(this.selectedShipFromId, this.selectedFromName)
    },
    selectPrintOption(documents) {
      let selectedDocument = null
      // eslint-disable-next-line no-restricted-syntax
      for (const doc of documents) {
        if (doc.format === 'PDF' && doc.size.width === 4 && doc.size.length === 6) {
          selectedDocument = doc
          return selectedDocument
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const doc of documents) {
        if (doc.format === 'PNG' && doc.size.width === 4 && doc.size.length === 6) {
          selectedDocument = doc
          return selectedDocument
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const doc of documents) {
        if (doc.size.width >= 4 && doc.size.width <= 6 && doc.size.length >= 4 && doc.size.length <= 6) {
          selectedDocument = doc
          return selectedDocument
        }
      }

      if (documents.length > 0) {
        selectedDocument = documents[0]
      }

      return selectedDocument
    },
    buyShippingLabel() {
      if (!this.selected.rateId) {
        Notify.warning('You need to select a rate by clicking on it.')
        return
      }

      fbmService.buyLabelAndMarkAsShipped(this.selected).then(async () => {
        Notify.success('Shipping label purchased successfully')
        const result = await fbmService.getShippingLabels(this.rateInformation.referenceId)
        if (this.selected.rateType === rateTypesEnum.AMAZON_FBM.value) {
          this.labelIds = result.map(el => el.id)
          this.ratePurchased = true
        } else {
          this.labelUrls = result.map(el => el.labelUrl)
          this.ratePurchased = true
        }
      }).finally(() => {
        this.selected = {
          isAmazonLabel: false,
        }
      })
    },
    async updateBoxWeightAndDimensions() {
      this.$emit('emit-update-dimension', this.order.labelDimension)
    },
    handleLabelDownload() {
      if (this.selected.rateType === rateTypesEnum.AMAZON_FBM.value && this.labelIds?.length > 0) {
        this.onDownloadFile(this.rateInformation.referenceId, this.labelIds)
      } else {
        this.labelUrls.forEach(url => {
          window.open(url, '_blank')
        })
      }
    },
    onDownloadFile(fbmOrderId, labelId) {
      return new Promise((resolve, reject) => {
        fbmService.getAmazonShippingLabelDocs(fbmOrderId, labelId)
          .then(response => {
            const contentType = response.headers['content-type']
            const contentDisposition = response.headers['content-disposition']
            const fileParts = contentDisposition.split('filename=')[1].replaceAll('"', '').split('.')
            const fileExtension = fileParts[1]
            const fileBlob = new Blob([response.data], { type: contentType })
            const anchor = document.createElement('a')
            anchor.href = window.URL.createObjectURL(fileBlob)
            anchor.download = `${fbmOrderId}-${labelId}.${fileExtension}`
            anchor.click()
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async deleteShippingLabel(labelId) {
      await fbmService.deleteShippingLabel(labelId)
    },
    async handleDeleteLabel() {
      const confirm = await this.pshConfirm(
        'Delete Shipping Label Confirmation',
        'Are you sure you want to delete this shipping label? <br/> If this label is purchased on PSH, it will be made void.',
        'exclamation-circle-fill',
        'Delete',
        'Cancel',
        'default',
      )
      if (confirm) {
        await this.deleteShippingLabel(this.labelIds[0])
        Notify.success('This shipping label has been deleted.')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.txt-btn {
  color: #1b557a;
  font-weight: bold;
  font-size: 14px;
}
.txt-btn:hover {
  color: #15b8cc;
  transition: color;
}
</style>
