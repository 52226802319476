/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */

import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'

export default function usePackingSlipDownload() {
  function removeNullUndefined(str) {
    return str.replace('null', '').replace('undefined', '')
  }

  function getAddressBeforeLastComma(address) {
    const lastCommaIndex = address.lastIndexOf(',')
    if (lastCommaIndex === -1) {
      return address
    }
    return address.slice(0, lastCommaIndex).trim()
  }

  function getAddressAfterLastComma(address) {
    const lastCommaIndex = address.lastIndexOf(',')
    if (lastCommaIndex === -1) {
      return ''
    }
    return address.slice(lastCommaIndex + 1).trim()
  }

  async function downloadPackingSlips(slips) {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'in',
      format: [4, 6],
      precision: 1,
      userUnit: 96,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    })

    slips.forEach((slip, index) => {
      if (index > 0) {
        doc.addPage()
      }
      doc.setFont('Helvetica', 'normal')
      doc.setFontSize(6)
      doc.text('Ship To:', 0.1, 0.2, { align: 'left' })
      doc.setFont('Helvetica', 'bold')
      doc.text(removeNullUndefined(slip.receiverName), 0.5, 0.2, { align: 'left' })
      doc.text(removeNullUndefined(getAddressBeforeLastComma(slip.shippingToAddress)), 0.1, 0.3, { align: 'left' })
      doc.text(removeNullUndefined(getAddressAfterLastComma(slip.shippingToAddress)), 0.1, 0.4, { align: 'left' })
      doc.setFont('Helvetica', 'normal')
      doc.text('---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------', 0.1, 0.5, { align: 'center' })
      doc.setFontSize(7)
      doc.setFont('Helvetica', 'bold')
      doc.text('Order ID:', 0.1, 0.65, { align: 'left' })
      doc.text(slip.id2, 0.6, 0.65, { align: 'left' })
      doc.setFontSize(5)
      doc.setFont('Helvetica', 'normal')
      doc.text(`Thank you for buying from ${slip.storeName || 'us'} on ${slip.marketplace || 'our marketplace'}`, 0.1, 0.75, { align: 'left' })

      const pageWidth = doc.internal.pageSize.getWidth()
      const pageHeight = doc.internal.pageSize.getHeight()
      const margin = 0.1
      const availableWidth = pageWidth - 2 * margin

      doc.setFont('Helvetica', 'normal')
      doc.setFontSize(7)

      // Set the text to be added
      const texts = [
        {
          content: 'Shipping Address:', x: margin + 0.05, y: 1, font: 'bold',
        },
        {
          content: removeNullUndefined(slip.receiverName), x: margin + 0.05, y: 1.13, font: 'normal',
        },
        {
          content: getAddressBeforeLastComma(removeNullUndefined(slip.shippingToAddress)), x: margin + 0.05, y: 1.25, font: 'normal',
        },
        {
          content: getAddressAfterLastComma(removeNullUndefined(slip.shippingToAddress)), x: margin + 0.05, y: 1.35, font: 'normal',
        },
        {
          content: `Order Date: ${slip.orderAt}`, x: margin + 0.05, y: 1.48, font: 'normal',
        },
        {
          content: `Shipping Service: ${slip.shippingService || '-'}`, x: margin + 0.05, y: 1.59, font: 'normal',
        },
        {
          content: `Seller: ${slip.storeName || '-'}`, x: margin + 0.05, y: 1.70, font: 'normal',
        },
      ]

      doc.setLineWidth(0.01) // Thin border
      doc.rect(margin, 0.85, availableWidth, 1)

      // Add text within the border
      texts.forEach(text => {
        doc.setFont('Helvetica', text.font)
        doc.text(text.content, text.x, text.y)
      })

      // Add a table for items
      const tableStartY = 1.9 // Y position where the table starts
      const tableColumnWidths = [0.3, 1.7, 0.5, 0.6, 0.7] // Widths for Quantity, Product Details, Unit Price, Item Totals

      // Define the table columns and data
      const columns = [
        { header: 'Qty', dataKey: 'quantity' },
        { header: 'Product Details', dataKey: 'productDetails' },
        { header: 'Unit Price', dataKey: 'price' },
        { header: 'Tax', dataKey: 'taxValue' },
        { header: 'Item Total', dataKey: 'itemTotal' },
      ]

      const items = slip.items.map(item => ({
        quantity: item?.quantity || 1,
        productDetails: `${item?.productName}${item?.marketplace === 'AmazonSP' ? `\nASIN: ${item?.productId || '-'}` : ''}\nSKU: ${item.orderSku || '-'}`,
        price: `$${item?.price || 0.00}`,
        taxValue: `$${item?.taxValue || 0.00}`,
        itemTotal: `$${item?.totalPrice || 0.00}`,
      }))

      // Add the table to the document
      autoTable(doc, {
        startY: tableStartY,
        margin: { left: 0.1, right: 0.1 },
        columns: columns,
        body: items,
        theme: 'striped',
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 6,
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          halign: 'center',
        }, // Horizontal alignment for header
        bodyStyles: {
          fontSize: 6,
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          halign: 'center',
          valign: 'middle',
        }, // Horizontal and vertical alignment for body
        columnStyles: {
          0: { cellWidth: tableColumnWidths[0], halign: 'center' }, // Center align text in Quantity column
          1: { cellWidth: tableColumnWidths[1], overflow: 'linebreak', halign: 'left' },
          2: { cellWidth: tableColumnWidths[2], halign: 'center' },
          3: { cellWidth: tableColumnWidths[3], halign: 'center' },
          4: { cellWidth: tableColumnWidths[4], halign: 'center' },
        },
        styles: {
          cellPadding: 0.05, // Adjust padding to control vertical alignment
        },
      })

      const totalsStartY = doc.lastAutoTable.finalY + 0.2 // Position after the table
      const totalsX = margin
      doc.setFont('Helvetica', 'bold')
      const shipping = JSON.parse(slip.orderTotals)?.shipping
      doc.text('Shipping + shipping tax:', totalsX + 2, totalsStartY)
      doc.text(`$${shipping?.toFixed(2) || '0.00'}`, pageWidth - margin - 0.1, totalsStartY, { align: 'right' })

      const grandTotal = JSON.parse(slip.orderTotals).total
      doc.text('Grand Total:', totalsX + 2, totalsStartY + 0.15)
      doc.text(`$${grandTotal.toFixed(2)}`, pageWidth - margin - 0.1, totalsStartY + 0.15, { align: 'right' })

      if (slip.marketplace === 'AmazonSP') {
        const additionalTextY = pageHeight - 0.3 // Position just above the bottom of the page
        doc.setFont('Helvetica', 'bold')
        doc.setFontSize(5)
        doc.text('Return and replace your item:', totalsX, additionalTextY)
        doc.setFont('Helvetica', 'normal')
        doc.text('Visit Amazon.com/returns', totalsX + 1, additionalTextY)
        doc.text('Have feedback on how we packaged your order? Tell us at Amazon.com/packaging', totalsX, additionalTextY + 0.1)
      }
    })

    doc.save('Packing_Slip.pdf')
  }

  return {
    downloadPackingSlips,
  }
}
