<template>
  <div>
    <img v-if="platform === 'Shopify'" height="32px" class="" width="auto" src="@/assets/images/svg/ecommerce/shopify.svg" alt="">
    <img v-else-if="platform === 'Amazon'" height="32px" class="" width="auto" src="@/assets/images/svg/ecommerce/amazon.svg" alt="">
    <img v-else-if="platform === 'AmazonSP'" height="32px" class="" width="auto" src="@/assets/images/svg/ecommerce/amazon.svg" alt="">
    <img v-else-if="platform === 'Ebay'" height="32px" class="" width="auto" src="@/assets/images/svg/ecommerce/ebay.svg" alt="">
    <img v-else-if="platform === 'Walmart'" height="32px" class="" width="auto" src="@/assets/images/svg/ecommerce/walmart.svg" alt="">
    <p-icon v-else name="bi-shop" />
  </div>
</template>

<script>
export default {
  props: {
    platform: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
